
import { useGetNotices } from "@/network/notice";
import { useGetDownloads } from "@/network/download";
import { defineComponent, inject, ref, onMounted, reactive } from "vue";
import { useQueryProjects } from "@/network/project.ts";
import roles from "@/types/roles.ts";
import Base from "./layout/Base.vue";
import { ElNotification } from "element-plus";
export default defineComponent({
  name: "Home",
  components: {
    Base,
    // CreateApply,
    // ProjectTable,
  },
  setup() {
    const showDialog = ref(false);
    const user: any = inject("user");
    const [notices] = useGetNotices();
    const [downloads] = useGetDownloads();
    const [isLoading, projects, projectQuery] = useQueryProjects({
      pageNum: 1,
    });
    const showProjectApprovalColumn = ref(true);
    const showFundColumn = ref(true);
    const showPlanColumn = ref(true);
    const showFinishColumn = ref(true);
    const filter = reactive({
      level: null,
    });

    const fastLinks = [
      // {
      //   title: "计划书审批",
      //   icon: "el-icon-s-claim",
      //   path: "/",
      //   color: "Green",
      // },
      // {
      //   title: "方案审批",
      //   icon: "el-icon-s-check",
      //   path: "/",
      //   color: "Red",
      // },
      // {
      //   title: "项目概览",
      //   icon: "el-icon-s-check",
      //   path: "/Fund/Review",
      //   color: "Red",
      //   roles: [roles.applyUnit, roles.city],
      // },
      // {
      //   title: user.inRoles(roles.prov) ? "评审计划书" : "报计划书",
      //   icon: "el-icon-s-claim",
      //   path: "/ReportAndReview/Setup",
      //   color: "Green",
      //   roles: [roles.applyUnit, roles.prov, roles.city],
      // },
      {
        title: "审核事项",
        icon: "el-icon-document-copy",
        path: "/ReportAndReview/Things",
        color: "Blue",
        roles: [roles.applyUnit, roles.prov, roles.city],
      },
      {
        title: "国家级计划书",
        icon: "el-icon-document-copy",
        path: "/ReportAndReview/NationSetup",
        color: "Blue",
        roles: [roles.applyUnit, roles.prov, roles.city],
      },
      {
        title: "省级计划书",
        icon: "el-icon-document-copy",
        path: "/ReportAndReview/ProvSetup",
        color: "Blue",
        roles: [roles.applyUnit, roles.prov, roles.city],
      },

      {
        title: "行政许可",
        icon: "el-icon-document-copy",
        path: "/PermissionPlan/Index",
        color: "Green",
        roles: [roles.applyUnit, roles.prov, roles.city],
      },
      {
        title: user.inRoles(roles.prov) ? "资金项目评审" : "资金项目申报",
        icon: "el-icon-s-promotion",
        path: "/Fund/Review",
        color: "Red",
        roles: [roles.applyUnit, roles.prov, roles.city],
      },

      {
        title: "申请资金",
        icon: "el-icon-s-check",
        path: "/Fund/Review",
        color: "Red",
        roles: [roles.apply],
      },

      // {
      //   title: "绩效填报",
      //   icon: "el-icon-edit-outline",
      //   path: "/Fund/JiXiao",
      //   color: "Red",
      //   roles: [roles.applyUnit, roles.city],
      // },
      // {
      //   title: "项目进度",
      //   icon: "el-icon-edit-outline",
      //   path: "/Fund/JiXiao",
      //   color: "Red",
      //   roles: [roles.applyUnit, roles.city, roles.prov],
      // },
      {
        title: "资金文件",
        icon: "el-icon-plus",
        path: "/Fund/Review?tab=alternativeLib",
        color: "Red",
        roles: [roles.prov, roles.city],
      },
      // {
      //   title: "资金安排",
      //   icon: "el-icon-check",
      //   path: "/Fund/Review?tab=giveFund",
      //   color: "Red",
      //   roles: [roles.prov],
      // },
      {
        title: "资金执行",
        icon: "el-icon-s-data",
        path: "/ProjectDo/FundRun",
        color: "Yellow",
        roles: [roles.prov],
      },
      {
        title: "项目进度执行",
        icon: "el-icon-s-data",
        path: "/ProjectDo/Status",
        color: "Yellow",
        roles: [roles.prov],
      },
      // {
      //   title: "季报",
      //   icon: "el-icon-upload",
      //   path: "/",
      //   color: "Orange",
      // },
      // {
      //   title: "填绩效",
      //   icon: "el-icon-document-copy",
      //   path: "/",
      //   color: "Turquoise",
      // },
      // {
      //   title: "申请验收",
      //   icon: "el-icon-s-flag",
      //   path: "/",
      //   color: "",
      // },
    ];
    onMounted(() => {
      projectQuery();
    });

    const heritageList = ref([
      {
        name: "鸡鸣山民居苑",
        img: "/static/images/jms.jpg",
        root: true,
      },
      { name: "岳飞墓", img: "/static/images/yfm.jpg" },
      { name: "临安城遗址", img: "/static/images/lac.jpg" },
    ]);
    const tableData = ref([
      {
        heritage: {
          name: "鸡鸣山民居苑",
          img: "/static/images/jms.jpg",
          root: true,
        },
        projectApproval: {
          licenseId: "文物保函﹝2017﹞1385号",
          name: "鸡鸣山民居苑修缮项目",
        },
        plan: {
          licenseId: "浙文物函﹝2020﹞54号",
          name: "测试",
        },
      },
      {
        heritage: {
          name: "鸡鸣山民居苑",
          img: "/static/images/jms.jpg",
          root: false,
        },
        projectApproval: {
          licenseId: "文物保函﹝2017﹞1385号",
          name: "鸡鸣山民居苑修缮项目",
        },
        plan: {
          licenseId: "浙文物函﹝2020﹞55号",
          name: "测试",
        },
      },
      {
        heritage: {
          name: "鸡鸣山民居苑",
          img: "/static/images/jms.jpg",
          root: false,
        },
        plan: {
          licenseId: "浙文物函﹝2020﹞54号",
          name: "测试",
        },
      },
      {
        heritage: { name: "岳飞墓", img: "/static/images/yfm.jpg" },
        projectApproval: {
          licenseId: "文物保函﹝2020﹞997号",
          name: "岳飞墓石质文物保护修缮前期勘察研究",
        },
      },
      {
        heritage: { name: "临安城遗址", img: "/static/images/lac.jpg" },
      },
    ]);

    const spanMethod = ({ row, column, rowIndex, columnIndex }) => {
      // if(columnIndex )
      const { projectApproval, plan, fund, finish, heritage } = row;

      if (columnIndex == 0) {
        if (heritage.root === undefined) {
          return;
        } else if (heritage.root === true) {
          const rowSpan = tableData.value.filter(
            (p) => p.heritage.name == heritage.name
          ).length;
          return {
            rowspan: rowSpan,
            colspan: 1,
          };
        } else {
          return [0, 0];
        }
      }
      // if (projectApproval == null) {
      //   if (columnIndex > 0 && columnIndex <= 4) {
      //     if (columnIndex == 1) {
      //       return {
      //         rowspan: 1,
      //         colspan: 4,
      //       };
      //     } else {
      //       return [0, 0];
      //     }
      //   }
      // }
      // if (plan == null) {
      //   if (columnIndex >= 5 && columnIndex <= 9)
      //     if (columnIndex == 5) {
      //       return {
      //         rowspan: 1,
      //         colspan: 5,
      //       };
      //     } else {
      //       return [0, 0];
      //     }
      // }
      // if (fund == null) {
      //   if (columnIndex >= 10 && columnIndex <= 16)
      //     if (columnIndex == 10) {
      //       return {
      //         rowspan: 1,
      //         colspan: 7,
      //       };
      //     } else {
      //       return [0, 0];
      //     }
      // }
      // if (finish == null) {
      //   if (columnIndex >= 17 && columnIndex <= 18)
      //     if (columnIndex == 17) {
      //       return {
      //         rowspan: 1,
      //         colspan: 2,
      //       };
      //     } else {
      //       return [0, 0];
      //     }
      // }
    };

    const showCreateApply = ref(false);

    // if (user.inRoles(roles.apply)) {
    //   setTimeout(() => {
    //     ElNotification({
    //       title: "进度填报预警",
    //       message: '项目 文物保护员补助经费,资金已下达3个月，尚未填报任何内容，请及时填报！',
    //       type: 'warning',
    //       duration: 10000
    //     })
    //   }, 1);

    //   setTimeout(() => {
    //     ElNotification({
    //       title: "进度填报预警",
    //       message: '项目 薪传古陶瓷博物馆建设项目 资金已下达6个月，还未填报招标信息，请及时填报！',
    //       type: 'warning',
    //       duration: 10000
    //     })
    //   }, 1);

    //   setTimeout(() => {
    //     ElNotification({
    //       title: "进度填报预警",
    //       message: '项目 齐长城章丘岳滋村至北门关段保护方案编制,工期已到一半，请及时填报！',
    //       type: 'warning',
    //       duration: 10000
    //     })
    //   }, 1);

    // }
    return {
      isLoading,
      projects: projects,
      fastLinks,
      user,
      notices,
      downloads,
      tableData,
      spanMethod,
      showProjectApprovalColumn,
      showFundColumn,
      showPlanColumn,
      showFinishColumn,
      filter,
      heritageList,
      showDialog,
      showCreateApply,
      createApply() {
        showCreateApply.value = true;
      },
    };
  },
});
